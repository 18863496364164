import React, { ReactElement } from 'react';
import { Box, Flex, SimpleGrid, Spacer } from '@chakra-ui/react';
import { Locale } from '../../../utils/types';
import { By } from '../../By';
import { DateTime } from '../../DateTime';
import { Heading } from '../../Heading';
import { Image } from '../../Image';
import { ImageRasterProps } from '../../ImageRaster';
import { ImageVectorProps } from '../../ImageVector';
import { NestedLocale } from '../../NestedLocale';
import { Paragraph } from '../../Paragraph';
import { PostPageVariant } from '../misc';

type TitleAreaWithImageProps = {
  variant: PostPageVariant;
  title: string;
  subtitle?: string;
  blogPostData?: {
    language: Locale;
    createdAt: string;
    authorNames?: string[];
    image: {
      gatsbyImageData?: ImageRasterProps['image'];
      svg?: { content: ImageVectorProps['image'] };
    };
  };
  eventPostData?: {
    language: Locale;
    start: string;
    end: string;
    location?: string;
    image: {
      gatsbyImageData?: ImageRasterProps['image'];
      svg?: { content: ImageVectorProps['image'] };
    };
  };
};

export const TitleAreaWithImage = ({
  variant,
  title,
  subtitle,
  blogPostData,
  eventPostData,
}: TitleAreaWithImageProps): ReactElement => (
  <SimpleGrid
    gridTemplateAreas={{
      base: '"image"\n"text"',
      l: variant === 'blogPost' ? '"image text"' : '"text image"',
    }}
    gridTemplateColumns={{
      'base': '1fr',
      'l': '1fr 1fr',
      '2xl': variant === 'blogPost' ? '1fr 2fr' : '2fr 1fr',
    }}
    gridTemplateRows={{
      base: '200px auto',
      xs: 'calc(200px + 150 * (100vw - 300px) / 300) auto',
      s: '350px auto',
      l: 'auto',
    }}
    marginY={'4em'}
    gap={'2em'}
  >
    {variant === 'blogPost' && blogPostData && (
      <Flex gridArea={'image'}>
        <Image image={blogPostData.image} />
      </Flex>
    )}
    {variant === 'eventPost' && eventPostData && (
      <Flex gridArea={'image'}>
        <Image image={eventPostData.image} />
      </Flex>
    )}
    <Flex flexDirection={'column'} gridArea={'text'}>
      <NestedLocale
        componentLocale={
          variant === 'blogPost' && blogPostData
            ? blogPostData.language
            : variant === 'eventPost' && eventPostData
            ? eventPostData.language
            : undefined
        }
      >
        <Heading level={3} marginTop={0}>
          {title}
        </Heading>
        {subtitle && (
          <Heading level={5} as={'p'}>
            {subtitle}
          </Heading>
        )}
      </NestedLocale>
      <Spacer />
      {variant === 'blogPost' && blogPostData && (
        <Box marginTop={'1.5em'}>
          <DateTime start={blogPostData.createdAt} dateOnly condensed />
          <By authors={blogPostData.authorNames} />
        </Box>
      )}
      {variant === 'eventPost' && eventPostData && (
        <Box marginTop={'1.5em'}>
          <Paragraph margin={0} fontSize={'0.9em'}>
            {eventPostData.location}
          </Paragraph>
          <DateTime
            start={eventPostData.start}
            end={eventPostData.end}
            condensed
          />
        </Box>
      )}
    </Flex>
  </SimpleGrid>
);
