import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Locale } from '../utils/types';
import { usePageContext } from '../context/PageContext';
import { ImageFromUrlProps } from '../components/ImageFromUrl';
import { ImageRasterProps } from '../components/ImageRaster';
import { RichTextData } from '../components/RichText';
import { ContentArea } from '../components/TemplateHelpers/3-ContentArea';
import { TitleAreaWithImage } from '../components/TemplateHelpers/2-TitleAreaWithImage';

export type EventPostQuery = {
  contentfulPageEventPost: {
    contentful_id: string;
    slug: string;
    language: Locale;
    title: string;
    subtitle?: string;
    start: string;
    end: string;
    location?: string;
    image: {
      gatsbyImageData?: ImageRasterProps['image'];
      fixed?: { src: ImageFromUrlProps['image'] };
    };
    content?: RichTextData;
    showTableOfContents?: boolean;
  };
};

const EventPost = ({ data }: PageProps<EventPostQuery>): ReactElement => {
  const { previousPath, nextPath } = usePageContext();

  const {
    title,
    subtitle,
    language,
    start,
    end,
    location,
    image,
    content,
    showTableOfContents,
  } = data.contentfulPageEventPost;

  return (
    <>
      <TitleAreaWithImage
        variant={'eventPost'}
        title={title}
        subtitle={subtitle}
        eventPostData={{
          language: language,
          start: start,
          end: end,
          location: location,
          image: image,
        }}
      />
      <ContentArea
        variant={'eventPost'}
        content={content}
        showTableOfContents={showTableOfContents}
        previousPath={previousPath}
        nextPath={nextPath}
      />
    </>
  );
};

export default EventPost;

export const query = graphql`
  query ($id: String!) {
    contentfulPageEventPost(id: { eq: $id }) {
      ... on ContentfulPageEventPost {
        ...EventPost
      }
    }
  }
`;
